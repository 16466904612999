export type DownloadedFiles =
  | 'expenseTemplate'
  | 'expenseReport'
  | 'expenseReceipts'
  | 'crewList'
  | 'boatUserList'
  | 'other'
  | 'boatDocumentList'
  | 'boatDocument'
  | 'myCv'
  | 'crewCvDocument'
  | 'crewCvDocumentList'
  | 'crewCvCourse'
  | 'crewCvCourseList'
  | 'crewCvReference'
  | 'crewCvBankDetails'
  | 'crewContractBankDetails'
  | 'boatCrewDocumentList'
  | 'boatCrewDocument'
  | 'charterTemplate'
  | 'chartersList'
  | 'charterData'
  | 'apaReportPDF'
  | 'apaReportExcel'
  | 'crewCv'
  | 'cardStatementsPdfReport'
  | 'cardStatementsExcelReport'
  | 'budgetComment'
  | 'availableDays'
  | 'fleetCharters'
  | 'tasksList'
  | 'inventoryList'
  | 'inventoryUsefulData'
  | 'inventoryTemplate'
  | 'tasksReport'
  | 'workListReport'
  | 'checklist'
  | 'checklistTemplate'
  | 'fleetTasksList'
  | 'bankAccountStatements';

export enum DownloadUrl {
  ExpenseTemplate = 'api/boat-finance/download-finance-template',
  ExpenseReceipts = 'api/boat-finance/download-finance-receipts',
  BoatUserList = 'api/boat/get-users-pdf',
  BoatDocument = 'api/boat-document/download-document-file',
  BoatDocumentVersion = 'api/boat-document/download-document-file-version',
  BoatDocumentList = 'api/boat-document/get-archive-document',
  BoatDocumentVersionArchive = 'api/boat-document/get-archive-document-version',
  CrewCvDocument = 'api/cv/download-document-file',
  CrewCvDocumentList = 'api/cv/download-document-archive',
  CrewCvCourse = 'api/cv/download-course-file',
  CrewCvReference = 'api/cv/download-yacht-experience-file',
  CrewCvContractReference = 'api/cv/download-contract-reference-file',
  CrewCvCourseList = 'api/cv/download-course-archive',
  CrewCvBankDetails = 'api/cv/download-bank-details-file',
  SupplierBankDetails = 'api/supplier/download-bank-details-file',
  SupplierBankDetailsArchive = 'api/supplier/download-bank-detail-archive',
  BoatCrewContractBankDetails = 'api/my-crew/download-bank-detail-file',
  BoatCrewDocumentList = 'api/my-crew/download-document-archive',
  BoatCrewDocument = 'api/my-crew/download-document-file',
  BoatCrewReference = 'api/my-crew/download-yacht-experience-file',
  BoatCrewCourseList = 'api/my-crew/download-course-archive',
  BoatCrewContractFile = 'api/my-crew/download-contract-file',
  BoatCrewContractReference = 'api/my-crew/download-contract-reference-file',
  CrewContractFile = 'api/user/download-contract-file',
  GuestAttachmentDocument = 'api/charter/download-guest-document-attachment',
  GuestPassportDocument = 'api/charter/download-guest-passport-attachment',
  CharterImportListTemplate = 'api/charter/get-import-template',
  CharterAttachmentFile = 'api/charter/download-charter-attachment-file',
  CharterAttachmentContractFiles = 'api/charter/download-charter-attachment-contract-archive',
  CharterAttachmentPreferenceFiles = 'api/charter/download-charter-attachment-pref-list-archive',
  CharterAttachmentCustomFiles = 'api/charter/download-charter-attachment-custom-document-archive',
  GuestAllAttachment = 'api/charter/download-guest-all-attachment',
  CharterInvoices = 'api/charter/download-charter-apa-receipts',
  BudgetComment = 'api/budget/get-budget-comment-archive',
  BudgetSingleComment = 'api/budget/get-budget-single-comment-archive',
  OutstandingInvoices = 'api/outstanding-payment/download-outstanding-payment-invoices',
  SupplierDocuments = 'api/supplier/download-document-file',
  SupplierDocumentsList = 'api/supplier/download-document-archive',
  SharedApaReport = 'api/apa/download-apa-report-archive-noauth',
  ChecklistTemplate = 'api/boat-check-list/download-form-template',
  InventoryUsefulData = 'api/boat-inventory/download-useful-data',
  InventoryTemplate = 'api/boat-inventory/download-template',
}

export enum QueueDownloadUrl {
  CrewList = 'api/my-crew/get-queue-my-crew-pdf',
  BoatMonthlyFinancialReport = 'api/boat-financial-report/get-queue-month-report-pdf',
  BoatYearFinancialReport = 'api/boat-financial-report/get-queue-year-report-pdf',
  BoatCustomFinancialReport = 'api/boat-financial-report/get-custom-report-pdf',
  MyCv = 'api/cv/get-queue-my-cv-pdf',
  CrewCv = 'api/cv/get-queue-crew-cv-pdf',
  GuestListPdf = 'api/charter/download-queue-guest-list-pdf',
  ApaReportPDF = 'api/charter/download-queue-apa-report-pdf',
  CharterOverview = 'api/charter/download-queue-charters-overview-pdf',
  CardStatementsPdfReport = 'api/credit-card-statement/get-queue-card-report-pdf',
  CardStatementsExcelReport = 'api/credit-card-statement/download-queue-card-excel-report',
  CharterBoatExpenses = 'api/charter/get-queue-boat-charter-expenses-pdf',
  FleetGuestListPdf = 'api/fleet-contacts/get-queue-download-guest-list-as-pdf',
  PettyCashReportPdf = 'api/boat-petty-cash-balance/download-queue-transaction-report-pdf',
  PettyCashReportExcel = 'api/boat-petty-cash-balance/download-queue-petty-cash-excel',

  ExpenseReport = 'api/boat-finance/download-queue-finance-data',
  BudgetExcel = 'api/budget/download-queue-budget-general',
  CharterList = 'api/charter/export-queue-charter-data',
  CharterData = 'api/charter/download-queue-charter-data-excel',
  AvailableDays = 'api/charter/download-queue-charter-available-excel-report',
  CharterCrewDownloadGuestsListExcel = 'api/charter/download-queue-guest-list-excel',
  ApaReportExcel = 'api/apa/download-queue-apa-excel-report',
  OutstandingData = 'api/outstanding-payment/download-queue-outstanding-payment-excel',
  MaintenanceSystemList = 'api/boat-equipment/download-queue-system-list-excel',
  BoatCrewList = 'api/my-crew/get-queue-my-crew-excel',
  FleetGuestListExcel = 'api/fleet-contacts/download-queue-guest-list-as-excel',
  FleetChartersList = 'api/fleet-charter/download-queue-charter-list',
  TasksList = 'api/boat-task/download-queue-task-log-excel',
  TasksReport = 'api/boat-maintenance/download-queue-dashboard-pdf',
  InventoryList = 'api/boat-inventory/download-queue-inventory',
  WorkList = 'api/boat-task-work-list/download-queue-work-list-pdf',
  Checklist = 'api/boat-check-list/download-queue-bucket',
  FleetTasksList = 'api/fleet-task/download-queue-tasks-excel',
  BankAccountStatements = 'api/bank-account-statement/download-queue-bank-account-excel-report',
}

export interface DownloadFileParams {
  [key: string]: string | number;
}

export interface DownloadQueueResponseDto {
  queueId: number;
}
