import {DOCUMENT} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {cleanObject} from '@idapgroup/js-object-utils';
import {LoaderService} from '@modules/custom-loader';
import {loader} from '@modules/custom-loader/models/decorators';
import {ImagePreviewService} from '@modules/image-preview';
import {NotificationService} from '@modules/notification';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {buildURLParams} from '@helpers/build-url-params';
import {fileExtension, fileExtensionFromCloudUrl} from '@helpers/file-extension';
import {FileSaver} from '@helpers/fileSaver';
import {httpParamsFromObject} from '@helpers/http-params';
import {
  HttpMediaResponseDto,
  MediaPreSignedPayload,
  MediaPreSignedUrlDto,
  MediaUploadStorageUrls,
} from '@models/cloud-media-uploader';
import {MediaUploaderService} from '@services/media-uploader/media-uploader.service';
import {ALL_IMAGES_TYPES} from '@static/media-types';

import {DownloadUrl} from '../models/download-files.model';

@Injectable()
export class FileDownloaderService {

  private readonly error = 'Error while downloading file';
  private readonly newTabExtensions = '.svg .png .jpeg .jpg .ico .gif .bmp .pdf';
  private readonly renderer: Renderer2;

  constructor(
    private readonly http: TransferHttpService,
    private readonly notificationService: NotificationService,
    private readonly rendererFactory: RendererFactory2,
    private readonly loaderService: LoaderService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly mediaUploaderService: MediaUploaderService,
    private readonly imagePreviewService: ImagePreviewService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  @loader()
  downloadFileByUrl<T extends Record<string, any>>(url: string, payload: T, buildParams = false): Observable<HttpResponse<Blob>> {
    const params = buildParams ? buildURLParams(cleanObject(payload)) : httpParamsFromObject(payload);
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params,
    });
  }

  parseAndSaveFile(res: HttpResponse<Blob>): boolean {
    const file = res.body;
    const contentDisposition = res.headers.get('content-disposition');
    const type = res.headers.get('content-type');
    if (file && contentDisposition && type) {
      try {
        const name = contentDisposition.match(new RegExp(/filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/));
        const filename = name && name[0] ? name[0].replace('filename=', '').split('"').join('') : '';
        this.saveFile(file, filename, type);
        return true;
      } catch (error) {
        this.notificationService.error(this.error);
        return false;
      }
    }
    this.notificationService.error(this.error);
    return false;
  }

  saveFile(file: Blob, filename: string, type: string): void {
    const blob = new Blob([file], {type});
    FileSaver.saveFile(blob, filename);
  }

  downloadCloudFile<T extends Record<string, any>>(
    url: DownloadUrl,
    payload: T,
    filename: string | null,
    alwaysDownload = false,
  ): Observable<boolean> {
    return this.getCloudPreSignedUrl(url, payload)
      .pipe(
        switchMap(data => this.downloadFileByPreSignedUrl(data, null, filename, alwaysDownload)),
        catchError((err) => {
          this.notificationService.error(err.message || this.error);
          return of(false);
        }),
      );
  }

  downloadCloudFileByUploadKey(
    url: MediaUploadStorageUrls,
    s3FileName: string,
    originalFileName: string | null = null,
    alwaysDownload = false,
  )
    : Observable<boolean> {

    const params: MediaPreSignedPayload = {key: `${url}/${s3FileName}`};
    return this.getCloudPreSignedUrlByUploadPath(params)
      .pipe(
        switchMap(data => this.downloadFileByPreSignedUrl(data, s3FileName, originalFileName, alwaysDownload)),
        catchError((err) => {
          this.notificationService.error(err.message || this.error);
          return of(false);
        }),
      );
  }

  downloadFileByPreSignedUrl(
    data: MediaPreSignedUrlDto,
    s3FileName: string | null,
    originalFileName: string | null,
    alwaysDownload = false,
  ): Observable<boolean> {
    if (alwaysDownload) {
      return this.downloadCloudFileByUrl(data.s3Url).pipe(
        map((res) => this.saveCloudBlobFile(res, originalFileName || s3FileName)),
      );
    }
    const {extension} = data;
    if (ALL_IMAGES_TYPES.includes(extension)) {
      this.imagePreviewService.showImage(data.s3Url, s3FileName || '');
      return of(true);
    }
    if (this.newTabExtensions.includes(extension)) {
      return of(this.saveFileInNewTab(data.s3Url));
    }
    return this.downloadCloudFileByUrl(data.s3Url).pipe(
      map((res) => this.saveCloudBlobFile(res, originalFileName || s3FileName)),
    );
  }

  @loader()
  private getCloudPreSignedUrl<T extends Record<string, any>>(url: DownloadUrl, payload: T): Observable<MediaPreSignedUrlDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<DownloadUrl>(url, {params}).pipe(
      map(s3Url => {
        const extension = fileExtensionFromCloudUrl(s3Url);
        return {s3Url, extension};
      }),
    );
  }

  @loader()
  getCloudPreSignedUrlByUploadPath<T>(params: MediaPreSignedPayload): Observable<MediaPreSignedUrlDto> {
    return this.mediaUploaderService.getPreSignedUrl(params).pipe(
      map(s3Url => {
        const extension = fileExtensionFromCloudUrl(s3Url);
        return {s3Url, extension};
      }),
    );
  }

  @loader()
  private downloadCloudFileByUrl(s3Url: string): Observable<HttpMediaResponseDto> {
    return this.http.get<HttpResponse<Blob>>(s3Url, {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(
      map(res => new HttpMediaResponseDto(res.body, s3Url)),
    );
  }

  saveFileInNewTab(url: string): boolean {
    const a = this.document.createElement('a');
    a.href = url;
    a.target = '_blank';
    const body = this.document.body;
    body.appendChild(a);
    setTimeout(() => {
      a.click();
      body.removeChild(a);
    });
    return true;
  }

  saveCloudBlobFile(mediaFile: HttpMediaResponseDto, name: string | null): boolean {
    const {downloadUrl, file} = mediaFile;
    if (!file) {
      this.notificationService.error(this.error);
      return false;
    }
    const {type} = file;
    const blob = new Blob([file], {type});
    const extension = fileExtension(name) || fileExtensionFromCloudUrl(downloadUrl);
    if (!extension) {
      return false;
    }
    const filename = name || `${this.getCurrentDate()}.${extension}`;
    FileSaver.saveFile(blob, filename);
    return true;
  }

  private getCurrentDate(): string {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
  }
}
